import DateTimeMixin from "./date-time.mixin";
import PermissionsMixin from "./permissions.mixin";

/**
 * Components that have a single `OneOnOne` event as their data
 */
const OneOnOneMixin = {
  mixins: [DateTimeMixin, PermissionsMixin],

  props: { headerItem: Boolean },

  computed: {
    assignedTeacher() {
      return this.oneOnOne
        ? this.oneOnOne.assignedTeacher
        : { fullName: "Practitioner" };
    },

    approvalExpires() {
      return (this.oneOnOne || {}).approvalExpiresDateTime;
    },

    approvalIsExpired() {
      return (this.oneOnOne || { approvalIsExpired: false }).approvalIsExpired;
    },

    approvalStatus() {
      return (this.oneOnOne || {}).approvalStatus;
    },

    canStart() {
      return (this.oneOnOne || { canStart: false }).canStart;
    },

    isApproved() {
      return (this.oneOnOne || { isApproved: false }).isApproved;
    },

    isAssignedUser() {
      const { activeUser } = this;
      const selected = this.oneOnOne;
      return Boolean(selected) && selected.assignedTeacher.id === activeUser.id;
    },

    isCanceled() {
      return (this.oneOnOne || { isCanceled: false }).isCanceled;
    },

    isComplete() {
      return (this.oneOnOne || { isComplete: false }).isComplete;
    },

    isConfirmed() {
      return (this.oneOnOne || { isConfirmed: false }).isConfirmed;
    },

    isDeclined() {
      return (this.oneOnOne || { isDeclined: false }).isDeclined;
    },

    isPending() {
      return (this.oneOnOne || { isPending: false }).isPending;
    },

    isRequestingUser() {
      const { activeUser } = this;
      const selected = this.oneOnOne;
      return (
        Boolean(selected && activeUser) &&
        selected.requestedBy.id === activeUser.id
      );
    },

    oneOnOneType() {
      return (this.oneOnOne || { name: "Name" }).name;
    },

    requestedBy() {
      return (this.oneOnOne || {}).requestedBy;
    },

    scheduledDateFormatted() {
      if (!this.oneOnOne) return "Date/Time";
      return this.formatDate(this.scheduledDateTime);
    },

    altDateFormatted() {
      return !this.oneOnOne || !this.oneOnOne.backupDateTime1
        ? null
        : this.formatDate(this.oneOnOne.backupDateTime1);
    },

    altDateFormatted2() {
      return !this.oneOnOne || !this.oneOnOne.backupDateTime2
        ? null
        : this.formatDate(this.oneOnOne.backupDateTime2);
    },

    scheduledDateTime() {
      return this.oneOnOne ? this.oneOnOne.scheduledDateTime : null;
    },

    scheduledDateTimeUTC() {
      return this.oneOnOne ? this.oneOnOne.scheduledDateTimeUTC : null;
    },

    scheduledTZ() {
      return this.oneOnOne ? this.oneOnOne.scheduledTZAbbr : "TZ";
    },

    statusColor() {
      return this.oneOnOne ? this.oneOnOne.statusIconColor : null;
    },

    statusDescription() {
      return this.oneOnOne ? this.oneOnOne.statusDescription : null;
    },

    statusIcon() {
      if (!this.oneOnOne) return "fas fa-info-circle primary-light--text";
      return this.oneOnOne.statusIcon;
    },

    statusIconColor() {
      return this.oneOnOne ? this.oneOnOne.statusIconColor : null;
    },

    statusText() {
      return this.oneOnOne ? this.oneOnOne.statusText : null;
    },

    teacherDateFormatted() {
      if (!this.assignedTeacher) return null;
      const { timeZone } = this.assignedTeacher;
      const date = this.scheduledDateTimeUTC.setZone(timeZone);
      return this.formatDate(date);
    },

    teacherAltDateFormatted() {
      const { oneOnOne, assignedTeacher } = this;
      if (!assignedTeacher || !oneOnOne || !oneOnOne.backupDateTime1) {
        return null;
      }

      const { timeZone } = assignedTeacher;
      return this.formatDate(oneOnOne.backupDateTime1UTC.setZone(timeZone));
    },

    teacherAltDateFormatted2() {
      const { oneOnOne, assignedTeacher } = this;
      if (!assignedTeacher || !oneOnOne || !oneOnOne.backupDateTime2) {
        return null;
      }

      const { timeZone } = assignedTeacher;
      return this.formatDate(oneOnOne.backupDateTime2UTC.setZone(timeZone));
    }
  },

  async created() {
    // Validate props and methods
    if (!this.oneOnOne && !this.headerItem) {
      const message =
        "OneOnOneMixin requires a 'oneOnOne' property on your component instance. " +
        "Make sure your component has a (computed, data, or props) property " +
        "called 'oneOnOne'. It should return a `OneOnOne` model, or null.";
      throw new Error(message);
    }
  }
};

export default OneOnOneMixin;
