<template>
  <custom-legend :items="legend" />
</template>

<script>
import {
  oneOnOneStatuses,
  statusIcon,
  statusIconColor
} from "../helpers/one-on-one.helpers";
import CustomLegend from "./CustomLegend.vue";
import PermissionsMixin from "./mixins/permissions.mixin";

export default {
  name: "OneOnOneListLegend",

  components: { CustomLegend },

  mixins: [PermissionsMixin],

  computed: {
    legend() {
      const { activeUser, isStudentUser, isScheduleAdminUser } = this;
      const grey = `grey--text`;
      const statuses = Object.keys(oneOnOneStatuses);
      const icon = t => {
        const status = oneOnOneStatuses[t];
        return `${statusIcon(status)} ${statusIconColor(status)}--text`;
      };

      return [
        // statuses
        ...statuses.map(text => ({
          icon: icon(text),
          text,
          condition: true
        })),
        {
          icon: `fas fa-calendar-times error-light--text`,
          text: `APPROVAL EXPIRED`,
          condition: true
        },
        // other icons
        { icon: `far fa-bookmark link`, text: `My request`, condition: true },
        {
          icon: `fas fa-bookmark error--text`,
          text: `Request for me`,
          condition: activeUser && !isStudentUser && !isScheduleAdminUser
        },
        {
          icon: `fas fa-people-arrows ${grey}`,
          text: `Practitioner`,
          condition: activeUser && !isStudentUser
        },
        {
          icon: `fas fa-user-circle ${grey}`,
          text: `Requester`,
          condition: activeUser && !isStudentUser
        },
        {
          icon: `fas fa-spa ${grey}`,
          text: `Staff Member`,
          condition: activeUser && !isStudentUser
        },
        {
          icon: `fas fa-square success-light--text`,
          text: `Subscriber`,
          condition: activeUser && !isStudentUser
        }
      ].filter(({ condition }) => condition);
    }
  }
};
</script>
