<template>
  <!-- Request Overview (Confirmed only) -->
  <div class="approve-one-on-one--overview">
    <list-one-on-one-status
      :one-on-one="oneOnOne"
      :show-description="isRequestingUser || !isScheduleAdminUser"
    />

    <!-- Schedule/Time zones -->
    <fieldset class="form--row time-zones">
      <legend class="grey-light--text">
        <i class="fas fa-clock link"></i> Schedule
      </legend>

      <p class="grey--text" v-if="isScheduleAdminUser">
        If a requested <strong>Practitioner</strong> is not available, select a
        user-suggested alternative date by clicking on it below.
        <template v-if="teacherSwapped || scheduleSwapped">
          Changes from the user's original request are shown in&nbsp;
          <b class="error-light--text"> red</b>.
        </template>
      </p>
      <hr class="divider divider--tiny" />

      <!-- User + Timezones -->
      <div
        class="list-item list-item--label list-item--user-timezone"
        v-for="(u, i) in userTimezones"
        :key="i"
      >
        <!-- Name -->
        <span>
          <i
            :class="{
              [u.icon]: true,
              'grey-light--text': !(teacherSwapped && i === 0),
              'error-light--text': teacherSwapped && i === 0
            }"
          />&nbsp;
          <b
            class="appt-user h6"
            :class="{ 'success--text': teacherSwapped && i === 0 }"
            v-text="u.name"
          />
        </span>

        <!-- Time -->
        <component :is="scheduleSwapped ? 'b' : 'span'" class="appt-time">
          <i
            v-if="u.time"
            class="fas fa-calendar"
            :class="{
              'grey-light--text': !scheduleSwapped,
              'error-light--text': scheduleSwapped
            }"
          />&nbsp;{{ u.time }}
        </component>

        <hr class="divider divider--tiny" />

        <template v-if="isScheduleAdminUser">
          <!-- Alt Schedules | Heading -->
          <div v-if="u.altSchedules.length">
            <i class="fas fa-calendar accent--text" />&nbsp;
            <b class="appt-user grey--text">Alternate Date/Times</b>
          </div>

          <!-- Alt Schedules | Dates List -->
          <span
            class="list-item--label alt-date appt-time"
            v-for="(date, i) in u.altSchedules"
            :key="i"
            @click.prevent="selectAltDate(i)"
          >
            <i
              :class="{
                'far fa-circle grey-light--text': i !== selectedIndex,
                'fas fa-check-circle link pulse': i === selectedIndex
              }"
            />&nbsp;
            {{ date }}
          </span>
        </template>

        <hr class="divider divider--tiny" />
      </div>

      <!-- "Check Instructor Availability" view -->
      <instructor-availability
        v-if="!hideTeacherAvailability"
        :one-on-one="oneOnOne"
        @on-availability="$emit('on-availability', $event)"
      />
    </fieldset>
  </div>
</template>

<script>
import ListOneOnOneStatus from "./ListOneOnOne.Status.vue";
import OneOnOneMixin from "./mixins/one-on-one.mixin";
import InstructorAvailability from "./InstructorAvailability.vue";

export default {
  name: "ApproveOneOnOneOverview",

  mixins: [OneOnOneMixin],

  components: { InstructorAvailability, ListOneOnOneStatus },

  props: {
    hideTeacherAvailability: Boolean,
    oneOnOne: Object,
    teacherSwapped: Boolean,
    scheduleSwapped: Boolean
  },

  data: () => ({ selectedIndex: -1 }),

  computed: {
    userTimezones() {
      const { assignedTeacher, requestedBy } = this.oneOnOne;
      const {
        teacherAltDateFormatted,
        altDateFormatted,
        teacherAltDateFormatted2,
        altDateFormatted2
      } = this;

      return [
        {
          // Teacher time
          icon: "fas fa-people-arrows",
          name: assignedTeacher.fullName,
          time: this.teacherDateFormatted,
          altSchedules: [
            teacherAltDateFormatted,
            teacherAltDateFormatted2
          ].filter(Boolean)
        },
        {
          // Student (requested) time
          icon: "fas fa-user-circle",
          name: requestedBy.fullName,
          time: this.scheduledDateFormatted,
          altSchedules: [altDateFormatted, altDateFormatted2].filter(Boolean)
        }
      ];
    }
  },

  methods: {
    selectAltDate(i) {
      if (this.oneOnOne) {
        this.selectedIndex = i === this.selectedIndex ? -1 : i;
        const { backupDate1, backupDate2 } = this.oneOnOne;
        const dates = [backupDate1, backupDate2];
        const next = dates[this.selectedIndex] || null;
        this.$emit("on-alt-date", next);
      }
    }
  }
};
</script>

<style lang="scss">
.approve-one-on-one--overview {
  .appt-time {
    font-size: $xs;

    &:not(.alt-date) {
      color: $grey;
    }
  }

  .alt-date {
    cursor: pointer;

    &:hover {
      color: $link;
    }
  }

  .form--row.time-zones {
    flex-wrap: wrap;
    padding-bottom: $xxs;
  }

  .list-item--user-timezone {
    @include flex-column;
    width: $half;

    > * {
      width: $parent;
    }
  }
}

@media screen and (max-width: 600px) {
  .approve-one-on-one--overview {
    .list-item--user-timezone {
      width: $parent;
    }
  }
}
</style>
