var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"approve-one-on-one--overview"},[_c('list-one-on-one-status',{attrs:{"one-on-one":_vm.oneOnOne,"show-description":_vm.isRequestingUser || !_vm.isScheduleAdminUser}}),_c('fieldset',{staticClass:"form--row time-zones"},[_vm._m(0),(_vm.isScheduleAdminUser)?_c('p',{staticClass:"grey--text"},[_vm._v(" If a requested "),_c('strong',[_vm._v("Practitioner")]),_vm._v(" is not available, select a user-suggested alternative date by clicking on it below. "),(_vm.teacherSwapped || _vm.scheduleSwapped)?[_vm._v(" Changes from the user's original request are shown in "),_c('b',{staticClass:"error-light--text"},[_vm._v(" red")]),_vm._v(". ")]:_vm._e()],2):_vm._e(),_c('hr',{staticClass:"divider divider--tiny"}),_vm._l((_vm.userTimezones),function(u,i){
var _obj;
return _c('div',{key:i,staticClass:"list-item list-item--label list-item--user-timezone"},[_c('span',[_c('i',{class:( _obj = {}, _obj[u.icon] = true, _obj['grey-light--text'] =  !(_vm.teacherSwapped && i === 0), _obj['error-light--text'] =  _vm.teacherSwapped && i === 0, _obj )}),_vm._v(" "),_c('b',{staticClass:"appt-user h6",class:{ 'success--text': _vm.teacherSwapped && i === 0 },domProps:{"textContent":_vm._s(u.name)}})]),_c(_vm.scheduleSwapped ? 'b' : 'span',{tag:"component",staticClass:"appt-time"},[(u.time)?_c('i',{staticClass:"fas fa-calendar",class:{
            'grey-light--text': !_vm.scheduleSwapped,
            'error-light--text': _vm.scheduleSwapped
          }}):_vm._e(),_vm._v(" "+_vm._s(u.time)+" ")]),_c('hr',{staticClass:"divider divider--tiny"}),(_vm.isScheduleAdminUser)?[(u.altSchedules.length)?_c('div',[_c('i',{staticClass:"fas fa-calendar accent--text"}),_vm._v(" "),_c('b',{staticClass:"appt-user grey--text"},[_vm._v("Alternate Date/Times")])]):_vm._e(),_vm._l((u.altSchedules),function(date,i){return _c('span',{key:i,staticClass:"list-item--label alt-date appt-time",on:{"click":function($event){$event.preventDefault();return _vm.selectAltDate(i)}}},[_c('i',{class:{
              'far fa-circle grey-light--text': i !== _vm.selectedIndex,
              'fas fa-check-circle link pulse': i === _vm.selectedIndex
            }}),_vm._v(" "+_vm._s(date)+" ")])})]:_vm._e(),_c('hr',{staticClass:"divider divider--tiny"})],2)}),(!_vm.hideTeacherAvailability)?_c('instructor-availability',{attrs:{"one-on-one":_vm.oneOnOne},on:{"on-availability":function($event){return _vm.$emit('on-availability', $event)}}}):_vm._e()],2)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('legend',{staticClass:"grey-light--text"},[_c('i',{staticClass:"fas fa-clock link"}),_vm._v(" Schedule ")])}]

export { render, staticRenderFns }