<template>
  <section :id="elemId" :class="{ active: visible, inactive: !visible }">
    <div class="content" v-if="visible">
      <h1 class="h4 sticky" v-if="title">{{ title }}</h1>
      <slot />

      <div class="form--row">
        <button
          class="button button--cancel"
          :class="{ 'col-50': confirmationText, wide: !confirmationText }"
          @click.prevent="$emit('close')"
        >
          <span class="error--text">Exit</span>
        </button>

        <template v-if="confirmationText">
          <hr class="divider divider--vertical" />

          <button class="outline grow" @click.prevent="$emit('confirm')">
            <span class="success--text">{{ confirmationText }}</span>
          </button>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ModalComponent",

  props: { confirmationText: String, title: String, visible: Boolean },

  data: () => ({ elemId: "modal" }),

  beforeDestroy() {
    this.removeEventListeners();
  },

  watch: {
    visible: function(isVisible, wasVisible) {
      if (isVisible) {
        window.addEventListener("keydown", this.onKeyEvent, false);
        window.addEventListener("click", this.onClickEvent, false);
      } else if (wasVisible) {
        this.removeEventListeners();
      }
    }
  },

  methods: {
    onClickEvent(event) {
      const { target } = event;
      if (target.id === this.elemId) {
        event.stopPropagation();
        return this.$emit("close");
      }
    },

    onKeyEvent({ key }) {
      if (key === "Escape" && this.visible) {
        return this.$emit("close");
      }
    },

    removeEventListeners() {
      window.removeEventListener("keydown", this.onKeyEvent, false);
      window.removeEventListener("click", this.onClickEvent, false);
    }
  }
};
</script>

<style lang="scss">
#modal {
  @include center-content;
  @include fill-screen;
  @include flex;
  background-color: $translucent;
  left: 0;
  margin: 0 !important;
  max-width: 100% !important;
  position: fixed;
  top: 0;
  z-index: 9999;

  &.active .content {
    @include expand-vertical;
  }

  &.inactive {
    @include fade-out;
    animation-fill-mode: forwards;
    animation-delay: 180ms;
    pointer-events: none;

    .content {
      @include collapse-vertical;
      animation-delay: 0;
    }
  }

  .button--cancel {
    @include button-outline;
    border: 0;
    box-shadow: none;
    margin: 0;

    &:hover {
      background-color: transparentize($color: $error, $amount: 0.5);
    }
  }

  .content {
    @include elevated;
    @include rounded;
    animation-delay: 180ms;
    background-color: white;
    max-height: 88vh;
    max-width: 60vw;
    overflow-x: hidden;
    overflow-y: auto;
    padding: $md;
    width: $parent;
  }

  .h4.sticky {
    top: -1.5rem;
  }
}

@media screen and (max-width: 1300px) {
  #modal {
    .content {
      max-width: 80vw;
    }
  }
}

@media screen and (max-width: 600px) {
  #modal {
    padding: 0 !important;

    &.active .content {
      height: $screen-height;
      max-height: $screen-height;
      max-width: $screen-width;
      width: $screen-width;
    }
  }
}

@media screen and (max-width: 500px) {
  #modal {
    &.active .content {
      padding-left: $sm;
      padding-right: $sm;
    }
  }
}
</style>
