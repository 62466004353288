<template>
  <!-- Instructors -->
  <fieldset class="instructors form--row row--wrap">
    <legend>
      <i class="fas fa-chalkboard-teacher link"></i>
      (Optional) Instructor
    </legend>

    <div class="form--row">
      <mvp-instructor-select
        class="grow"
        :show-icon="false"
        :value="oneOnOne.assignedTeacher"
        @change="$emit('swap-teacher', $event.data)"
        instructor-type="Practitioners"
        label-text="Change Practitioner"
      />

      <hr class="divider divider--vertical" />

      <mvp-instructor-select
        class="col-50"
        :show-icon="false"
        :value="oneOnOne.assignedTeacher"
        @change="$emit('swap-teacher', $event.data)"
        instructor-type="Teachers"
        label-text="Assign to Teacher"
      />
    </div>
  </fieldset>
</template>

<script>
import OneOnOneMixin from "./mixins/one-on-one.mixin";
import PermissionsMixin from "./mixins/permissions.mixin";
import MvpInstructorSelect from "./MvpInstructorSelect.vue";

export default {
  name: "ApproveOneOnOneInstructor",

  mixins: [PermissionsMixin, OneOnOneMixin],

  components: { MvpInstructorSelect },

  props: { oneOnOne: Object }
};
</script>

<style lang="scss" scoped src="./ApproveOneOnOne.Form.scss"></style>
