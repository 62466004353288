<template>
  <aside id="instructor-availability" class="list-item list-item--label">
    <em v-if="loading" class="slide-in-right loading-message">
      <b>Checking Availability...</b>
    </em>

    <strong
      v-else
      class="slide-in-right"
      :class="{ 'error--text': error, 'success--text': !error }"
    >
      <i
        class="fas"
        :class="{ 'fa-exclamation-triangle': error, 'fa-check-circle': !error }"
      />
      {{ conflictCheckResult || error }}
    </strong>
  </aside>
</template>

<script>
import { checkTeacherAvailability } from "../models/oneOnOne";
import OneOnOneMixin from "./mixins/one-on-one.mixin";

export default {
  name: "InstructorAvailability",

  mixins: [OneOnOneMixin],

  props: { oneOnOne: Object },

  data: () => ({ error: null, loading: true, conflictCheckResult: null }),

  watch: {
    oneOnOne: function _onOneOnOneUpdate(newProps) {
      if (newProps && this.oneOnOne) {
        if (newProps.id === this.oneOnOne) return;
      }

      return this.checkAvailability();
    }
  },

  mounted() {
    this.checkAvailability();
  },

  methods: {
    async checkAvailability() {
      this.loading = true;
      this.error = null;
      this.conflictCheckResult = null;

      try {
        this.conflictCheckResult = await checkTeacherAvailability({
          id: this.assignedTeacher.id,
          scheduledDate: this.oneOnOne.scheduledDate
        });
      } catch (error) {
        this.error = error.message || error;
      } finally {
        this.$emit(
          "on-availability",
          this.conflictCheckResult === "No conflicts"
        );
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss">
#instructor-availability {
  @include rounded;
  background-color: $grey-white;
  border: $xtiny dashed $grey-light;
  padding-left: $sm;

  .loading-message {
    color: $grey;

    &::before {
      @include after-spinner($translucent, $sm);
      margin-right: $xxs;
    }
  }
}
</style>
