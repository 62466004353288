<template>
  <div
    role="menuitem"
    :class="{
      'list-item list-item--one-on-one': true,
      'list-item--header': !oneOnOne,
      condensed
    }"
    @click.stop="clickable && $emit('click')"
  >
    <!-- Practitioner/Requester -->
    <span class="users-column">
      <!-- Teacher -->
      <span class="user grow">
        <i :class="teacherIcon"></i>
        <span :class="teacherSwapped && 'success--text'">
          {{ assignedTeacher.fullName }}
        </span>
        <i class="fas fa-certificate error--text" v-if="teacherSwapped"></i>
      </span>

      <!-- Requester -->
      <span class="user no-shrink" v-if="isScheduleAdminUser || isAssignedUser">
        <i :class="userIcon"></i>
        <span :class="studentSwapped && 'success--text'">
          {{ requestedBy ? requestedBy.fullName : "Requester" }}
        </span>
        <i class="fas fa-certificate error--text" v-if="studentSwapped"></i>
      </span>
    </span>

    <!-- Name -->
    <span class="name">
      <i :class="statusIcon" />&nbsp;{{ oneOnOneType }}
    </span>

    <!-- Date-time -->
    <span class="date-column" :title="scheduledDateFormatted">
      {{ scheduledDateFormatted }}
    </span>
  </div>
</template>

<script>
import { isStudent } from "../models/permissions";
import OneOnOneMixin from "./mixins/one-on-one.mixin";
import PermissionsMixin from "./mixins/permissions.mixin";

export default {
  name: "OneOnOneListItem",

  mixins: [PermissionsMixin, OneOnOneMixin],

  props: {
    condensed: Boolean,
    clickable: { type: Boolean, default: true },
    oneOnOne: Object,
    studentSwapped: Boolean,
    teacherSwapped: Boolean
  },

  computed: {
    isAssignedUser() {
      if (!(this.assignedTeacher && this.activeUser)) return false;
      const { assignedTeacher, activeUser } = this;
      const { fullName } = activeUser || {};
      return fullName === (assignedTeacher || {}).fullName;
    },

    teacherIcon() {
      const { isScheduleAdminUser, isAssignedUser } = this;

      return {
        "far fa-bookmark link": !isScheduleAdminUser && !isAssignedUser,
        fas: isScheduleAdminUser || isAssignedUser,
        "fa-people-arrows grey-light--text": isScheduleAdminUser,
        "fa-bookmark error--text": isAssignedUser
      };
    },

    userIcon() {
      if (!this.oneOnOne) return "fas fa-user-circle grey-light--text";
      const { requestedBy } = this.oneOnOne;
      const isStudentUser = isStudent(requestedBy);
      return {
        "success-light--text": requestedBy.stripeSubActive,
        "grey-light--text": !requestedBy.stripeSubActive,
        "fas fa-user-circle": isStudentUser,
        "fas fa-spa": !isStudentUser
      };
    }
  }
};
</script>

<style lang="scss" src="./OneOnOneListItem.scss"></style>
