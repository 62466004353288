<template>
  <section class="list--one-on-one route-content">
    <h3 class="h5">{{ listTitle }}</h3>
    <hr class="divider" />

    <!-- Legend + Controls -->
    <aside class="flex" v-if="requests.length">
      <!-- Filter List -->
      <multi-select-list
        select-prompt="Filter Status:"
        :filter-options="oneOnOneStatuses"
        @filters-changed="applyListFilters"
      />

      <hr class="divider divider--vertical" />

      <!-- List Sort Filter -->
      <multi-select-list
        select-prompt="Sort List:"
        :filter-options="sortRequestFilters"
        @filters-changed="applySortFilters"
      />

      <hr class="divider divider--vertical" />

      <!-- Legend -->
      <one-on-one-list-legend />
    </aside>

    <!-- 1:1 List Items -->
    <one-on-one-list-item header-item :clickable="false" />

    <!-- "Empty List" message -->
    <template v-if="showEmptyMessage && (isEmpty || noResults)">
      <p class="list-item list-item--label wide">
        <span>{{ listEmptyMessage || "No requests match your filters." }}</span>
      </p>
      <hr class="divider grey-light" />
    </template>

    <!-- Custom "Empty List" message -->
    <slot v-if="!showEmptyMessage" name="empty" />

    <one-on-one-list-item
      v-for="(item, i) in items"
      :key="item.id"
      :one-on-one="item"
      @click="select(item, i)"
    />
  </section>
</template>

<script>
import sortBy from "lodash.sortby";
import PermissionsMixin from "./mixins/permissions.mixin";
import OneOnOneListItem from "./OneOnOneListItem.vue";
import OneOnOneListLegend from "./OneOnOneList.Legend.vue";
import MultiSelectList from "./MultiSelectList.vue";
import { oneOnOneStatuses as statuses } from "../helpers/one-on-one.helpers";

export default {
  name: "OneOnOneList",

  mixins: [PermissionsMixin],

  props: {
    enableAdmin: Boolean,
    listTitle: { type: String, default: "My Requests" },
    listEmptyMessage: { type: String, default: "No requests found" },
    requests: { type: Array, default: () => [] },
    showLegend: Boolean,
    showEmptyMessage: { type: Boolean, default: true }
  },

  components: {
    OneOnOneListItem,
    OneOnOneListLegend,
    MultiSelectList
  },

  data: () => ({
    approvalStatusFilter: new Set(),
    sortFilter: new Set(),
    oneOnOneStatuses: Object.keys(statuses).map(k => statuses[k])
  }),

  computed: {
    hiddenFilters() {
      const { isStudentUser, isTeacherUser } = this;
      const filters = [
        "id",
        "approved",
        "approvalExpires",
        "createdAt",
        "requiresDonation",
        "zoomLink",
        "zoomMeetingId",
        "zoomStartLink"
      ];

      // hide additional keys
      if (isStudentUser) filters.push("requestedBy");
      if (isTeacherUser) filters.push("assignedTeacher");

      return filters;
    },

    items() {
      const { requests, sortFilter } = this;
      if (sortFilter.size === 0) return this.filterList([...requests]);

      // Apply "sort by" filters
      const userFilters = ["assignedTeacher", "requestedBy"];
      const excludeUserFilters = f => !userFilters.includes(f);
      const filters = [...sortFilter].filter(excludeUserFilters);

      // replace "user" keys with a function filter for lodash
      const getName = k => o => o[k].fullName;
      const addFilter = k => sortFilter.has(k) && filters.push(getName(k));
      userFilters.forEach(addFilter);

      return this.filterList(sortBy(requests, filters));
    },

    isEmpty() {
      return this.requests.length === 0;
    },

    noResults() {
      return this.approvalStatusFilter.size > 0 && this.items.length === 0;
    },

    sortRequestFilters() {
      return this.isEmpty
        ? []
        : [
            "approvalStatus",
            "assignedTeacher",
            "name",
            "requestedBy",
            "scheduledDate"
          ];
    }
  },

  methods: {
    applyListFilters(filters) {
      this.approvalStatusFilter = new Set(filters);
    },

    applySortFilters(filters) {
      this.sortFilter = new Set(filters);
    },

    filterList(list) {
      const { approvalStatusFilter } = this;
      return approvalStatusFilter.size === 0
        ? list
        : list.filter(({ approvalStatus }) =>
            approvalStatusFilter.has(approvalStatus)
          );
    },

    select(selected, index) {
      const params = { selected, index };
      return this.$emit("select-item", params);
    }
  }
};
</script>

<style lang="scss">
.list--one-on-one {
  .list-item--one-on-one {
    cursor: pointer;
  }

  em.list-item {
    border: 0;
  }
}
</style>
