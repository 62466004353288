<template>
  <form
    class="one-on-one--approval-form form-component"
    name="approval"
    @submit.prevent
  >
    <!-- Request Overview (Confirmed only) -->
    <approve-one-on-one-overview
      :hide-teacher-availability="!isPending"
      :one-on-one="formData"
      :schedule-swapped="swappedSchedule"
      :teacher-swapped="swappedTeacher"
      @on-availability="schedulingConflict = $event === false"
      @on-alt-date="$emit('swap-schedule', $event)"
    />

    <template v-if="isPending">
      <!-- "Change Practitioner" notice -->
      <p class="grey--text">
        You can
        <b>
          change the requested
          <span class="accent--text">Practitioner</span>
        </b>
        if <b>{{ teacherName }}</b> is not available, or assign the request to a
        non-IAYT-certified <b class="accent--text">Teacher</b> or
        <b class="accent--text">Therapist-in-training</b>.
      </p>

      <!-- Instructors -->
      <approve-one-on-one-instructor
        v-if="!deleteRequest"
        :oneOnOne="oneOnOne"
        @swap-teacher="$emit('swap-teacher', $event)"
      />

      <!-- Donation -->
      <label
        for="requiresDonation"
        class="list-item list-item--label flex"
        @click.stop="form.requiresDonation = !form.requiresDonation"
      >
        <input
          type="checkbox"
          name="requiresDonation"
          @change="emitFormData"
          v-model="form.requiresDonation"
        />
        <span class="checkbox-label">
          <i class="fas fa-hand-holding-heart error--text"></i>&nbsp; This
          requires a minimum donation for approval <b>(optional)</b>
        </span>
      </label>
    </template>

    <!-- Approval Actions and 'Trigger delete' Button  -->
    <approve-one-on-one-controls
      :canceling-request="cancelRequest"
      :declining-request="declineRequest"
      :deleting-request="deleteRequest"
      :disable-confirm="disableConfirm"
      :one-on-one="oneOnOne"
      @cancel-request="cancelRequest = true"
      @confirm-action="onConfirm"
      @decline-action="onDecline"
      @decline-request="declineRequest = true"
      @delete-request="deleteRequest = true"
    />
  </form>
</template>

<script>
import FormsMixin from "./mixins/forms.mixin";
import OneOnOneMixin from "./mixins/one-on-one.mixin";
import PermissionsMixin from "./mixins/permissions.mixin";
import ApproveOneOnOneControls from "./ApproveOneOnOne.Controls.vue";
import ApproveOneOnOneInstructor from "./ApproveOneOnOne.Instructor.vue";
import ApproveOneOnOneOverview from "./ApproveOneOnOne.Overview.vue";

export default {
  name: "ApproveOneOnOneForm",

  mixins: [PermissionsMixin, FormsMixin, OneOnOneMixin],

  components: {
    ApproveOneOnOneControls,
    ApproveOneOnOneInstructor,
    ApproveOneOnOneOverview
  },

  props: { swappedSchedule: Boolean, swappedTeacher: Boolean },

  data: () => ({
    cancelRequest: false,
    declineRequest: false,
    deleteRequest: false,
    form: { requiresDonation: false },
    schedulingConflict: false,
    requests: [],
    requiredFields: ["requiresDonation"],
    teacherName: null
  }),

  computed: {
    disableConfirm() {
      const { schedulingConflict, oneOnOne } = this;
      return schedulingConflict || !oneOnOne.assignedTeacher.zoomUserId;
    },

    oneOnOne() {
      return this.formData;
    }
  },

  mounted() {
    const { oneOnOne } = this;
    this.teacherName = oneOnOne.assignedTeacher.firstName;
  },

  methods: {
    onConfirm() {
      switch (true) {
        case this.deleteRequest:
          return this.$emit("delete-request");

        case this.cancelRequest:
          return this.$emit("cancel-request");

        case this.declineRequest:
          return this.$emit("decline");

        default:
          return this.$emit("approve");
      }
    },

    onDecline() {
      switch (true) {
        case this.deleteRequest:
          return (this.deleteRequest = false);

        case this.cancelRequest:
          return (this.cancelRequest = false);

        case this.declineRequest:
          return (this.declineRequest = false);

        default:
          return this.$emit("decline");
      }
    }
  }
};
</script>

<style lang="scss" scoped src="./ApproveOneOnOne.Form.scss"></style>
