// Categories
export const YOGA_THERAPY = {
  name: "Yoga Therapy",
  description: `Yoga Therapy is a combination of yoga, mindfulness and meditation that is 
  designed to help clients integrate on a truly holistic level and work towards a more 
  balanced experience in all four energetic bodies.\n
  Clients are coached through various therapeutic techniques including Dialectical 
  Behavioral Therapy, meditation, journaling, and the physical practice of yoga.\n
  My Vinyasa Practice is dedicated to using these tools to create a comprehensive plan 
  that will help you to find balance and peace in your daily life. Our approach to Yoga 
  Therapy meets you where you are at and helps you to achieve a sustainable lifestyle 
  that fosters peace and balance in all four energetic bodies`
};

export const YOGA_TEACHER_MENTORSHIP = {
  name: "Yoga Teacher Mentorship",
  description: `Yoga Teacher Mentorship, with Michelle Young, is designed to give Yoga 
  Teachers and Yoga Therapists the opportunity to have a productive professional 
  relationship with an established peer in the field. The Yoga Teacher Mentorship allows 
  for direction and advice with regards to launching a successful career in the health 
  and wellness industry. Michelle’s Yoga Teacher Mentorship is designed for yoga teachers 
  that are both new or experienced in the industry and would like to continue their journey 
  and bring new techniques and experiences to the mat.`
};

export const ONLINE_COACHING = {
  name: "Online Coaching",
  description: `Online Coaching, with Michelle Young, helps individuals identify old 
  patterns of behavior, work through limiting beliefs, and ultimately move into a state of 
  balance and abundance. The program marries Eastern and Western philosophies of health and 
  wellness and creates an experiential learning environment that helps you to step into your 
  power. Michelle’s Online Coaching program is designed for anyone looking to find balance 
  and step into their potential.`
};
