<template>
  <!-- Status -->
  <div :class="`status-text ${statusColor}--text`">
    <strong class="wide"><i :class="statusIcon" /> {{ statusText }}</strong>

    <span
      class="status-description wide"
      v-if="showDescription && statusDescription"
    >
      {{ statusDescription }}
    </span>

    <slot v-else name="description" />

    <!-- Approval Expires -->
    <span
      v-if="Boolean(approvalExpires) && isApproved"
      class="approval-expiry wide"
    >
      <b>{{ approvalIsExpired ? "Expired" : "Expires" }}</b>
      {{ approvalExpires.toRelative({ unit: "hours" }) }}
    </span>
  </div>
</template>

<script>
import OneOnOneMixin from "./mixins/one-on-one.mixin";

export default {
  name: "ListOneOnOneStatus",

  mixins: [OneOnOneMixin],

  props: {
    oneOnOne: Object,
    showDescription: { type: Boolean, default: true }
  }
};
</script>

<style lang="scss">
.status-text {
  @include flex-column;
  border: 0.5px dashed $grey-white;
  margin-bottom: $xs;
  margin-top: $xs;
  padding: $sm;

  .approval-expiry {
    font-size: smaller;
    color: $grey;
  }

  .status-description {
    padding-top: 0;
    font-weight: 300;
  }
}
</style>
