/* eslint-disable no-undef */
import {
  YOGA_TEACHER_MENTORSHIP,
  YOGA_THERAPY,
  ONLINE_COACHING
} from "./one-on-one.categories";

export const oneOnOneTypes = [
  {
    title: "Mindfulness Coaching",
    length: 60,
    category: ONLINE_COACHING
  },
  {
    title: "Personal/Professional Development",
    length: 30,
    category: YOGA_TEACHER_MENTORSHIP
  },
  { title: "Private Yoga Session", length: 60, category: YOGA_THERAPY },
  { title: "Yoga Therapy Clinic", length: 60, category: YOGA_THERAPY },
  { title: "Yoga Therapy", length: 60, category: YOGA_THERAPY },
  { title: "Zoom Private Yoga session", length: 60, category: YOGA_THERAPY }
];

// Status
const PENDING = "Pending";
const APPROVED = "Approved";
const CONFIRMED = "Confirmed";
const CANCELED = "Canceled";
const COMPLETE = "Complete";
const DECLINED = "Declined";
export const oneOnOneStatuses = {
  PENDING,
  APPROVED,
  CONFIRMED,
  CANCELED,
  COMPLETE,
  DECLINED
};

export function categoryFor({ name }) {
  const type = oneOnOneTypes.find(({ title }) => title === name);
  return type ? type.category : ONLINE_COACHING;
}

export function categoryDescriptionFor({ name }) {
  return categoryFor({ name }).description;
}

export function statusDescription(status) {
  const descs = {
    [PENDING]: `You will receive an email notification when ${APP_ORG} has 
    reviewed this request.`,
    [APPROVED]: `Your request has been approved! Please confirm the appointment 
    by taking any action(s) below:`,
    [CONFIRMED]: `Your appointment has been confirmed! Return here on the day of
     the event to find a meeting link.`,
    [CANCELED]: `This appointment was canceled. If you feel this is our error, 
    please contact admin@myvinyasapractice.com for assistance.`,
    [DECLINED]: `We were unable to accommodate this request. Please consider scheduling another Scheduling request, or reach out to
    admin@myvinyasapractice.com if you are having technical issues.`
  };

  return descs[status] || null;
}

export function statusIcon(approvalStatus = PENDING) {
  const color = statusIconColor(approvalStatus);
  const glyphs = {
    [PENDING]: "inbox-in",
    [APPROVED]: "exclamation-circle",
    [CONFIRMED]: "calendar-check",
    [COMPLETE]: "check-circle",
    [CANCELED]: "times-circle",
    [DECLINED]: "comment-slash"
  };

  return `i fas fa-${glyphs[approvalStatus]} ${color}--text`;
}

export function statusIconColor(approvalStatus = PENDING) {
  const colors = {
    [PENDING]: "grey",
    [APPROVED]: "accent",
    [CONFIRMED]: "success",
    [COMPLETE]: "success",
    [CANCELED]: "error-light",
    [DECLINED]: "error"
  };

  if (colors[approvalStatus]) return colors[approvalStatus];
  throw new Error(`Invalid approvalStatus "${approvalStatus}"`);
}

export function statusText(approvalStatus = PENDING) {
  const text = {
    [PENDING]: "Received (Awaiting Approval)",
    [APPROVED]: "Awaiting user confirmation",
    [CONFIRMED]: "Appointment confirmed",
    [CANCELED]: "Request Canceled",
    [DECLINED]: "Request Declined",
    [COMPLETE]: "Event completed"
  };

  return text[approvalStatus];
}
