<template>
  <!-- Approval Actions and 'Trigger delete' Button  -->
  <fieldset class="form--row form-controls wrap">
    <legend>
      <i
        class="fas"
        :class="{
          'fa-tools link': !deletingRequest,
          'fa-times-circle error--text': deletingRequest
        }"
      />
      {{ deletingRequest ? "Confirm Delete" : "Actions" }}
    </legend>

    <!-- One-on-One Status description -->
    <div class="list-item list-item--label column" v-if="!decliningRequest">
      <p :class="{ 'error--text': cancelingRequest }">
        <template v-if="isPending && !needsConfirmation">
          <b>Approve</b>, <b>decline</b>, or <b>delete</b> this Scheduling
          request.
        </template>

        <!-- (user) confirmed -->
        <span v-if="isConfirmed">
          <i
            class="fas"
            :class="{
              'fa-exclamation-triangle accent--text': needsConfirmation,
              [`${statusIcon} ${statusColor}--text`]: !needsConfirmation
            }"
          ></i
          >&nbsp; <b>{{ oneOnOne.requestedBy.fullName }}</b> confirmed they will
          be attending this <b>{{ oneOnOne.name }}</b
          >{{ cancelingRequest ? "!" : "." }}
        </span>

        <template v-else>
          <span v-if="isApproved || isDeclined || isCanceled">
            <i :class="`${statusIcon} ${statusColor}--text`"></i>&nbsp; This
            request
          </span>

          <!-- (admin) approved -->
          <template v-if="isApproved">
            <!-- approval expired -->
            <span v-if="approvalIsExpired">
              <b :class="`${statusColor}--text`">expired</b> because the user
              did not confirm their attendance in time.
            </span>
            <!-- Awaiting confirmation -->
            <span v-else>
              is <b :class="`${statusColor}--text`">approved</b> and awaiting
              user confirmation.
            </span>
          </template>

          <!-- (user) declined -->
          <template v-if="isDeclined">
            was <b :class="`${statusColor}--text`">declined</b> by either
            <b>{{ appOrgName }}</b> or the requester.
          </template>

          <!-- (admin/user) canceled -->
          <template v-if="isCanceled">
            was <b :class="`${statusColor}--text`">canceled</b> by
            <b>{{ appOrgName }}</b
            >.
          </template>
        </template>
      </p>

      <p class="error--text" v-if="!oneOnOne.assignedTeacher.zoomUserId">
        <b>{{ oneOnOne.assignedTeacher.fullName }}</b> does not have a
        <b>Zoom account</b>!
      </p>
    </div>

    <!-- Confirmations  -->
    <template v-if="needsConfirmation && (cancelingRequest || deletingRequest)">
      <p class="slide-up-fade-in">
        <i class="fas fa-exclamation-triangle accent--text" />&nbsp;

        <!-- Confirmations | Cancel Request -->
        <template v-if="cancelingRequest">
          Please note that <b>canceling a request</b> is different from
          <em><b>declining</b></em> one. Use the
          <b class="error--text">
            <i class="fas fa-times-circle" />
            Decline
          </b>
          button to address scheduling conflicts.
        </template>

        <!-- Confirmations | Delete Request -->
        <template v-if="deletingRequest">
          <b class="error--text">Only use in extreme cases!</b>
          Please note that <b>deleting</b> a request is different from
          <em><b>declining</b></em> one.
        </template>
      </p>

      <hr class="divider grey-white" />
    </template>

    <p class="slide-up-fade-in" v-if="needsConfirmation">
      Are you sure you want to
      <b class="error--text" v-if="decliningRequest">decline</b>
      <b class="error--text" v-if="deletingRequest">delete</b>
      <b class="error--text" v-if="cancelingRequest">cancel</b>
      this
      <b>{{ oneOnOne.name }}</b> request from
      <b>{{ oneOnOne.requestedBy.fullName }}</b
      >? This action cannot be undone.
    </p>

    <!-- Actions/Button Controls -->
    <template v-for="(action, i) in actions">
      <button
        :key="`${i}-btn`"
        :class="action.class"
        :disabled="action.disabled"
        @click.prevent="action.click"
      >
        <i :class="action.icon"></i>
        {{ action.text }}
      </button>

      <hr
        :key="`${i}-divider`"
        v-if="i < actions.length - 1"
        class="divider divider--vertical"
      />
    </template>
  </fieldset>
</template>

<script>
import OneOnOneMixin from "./mixins/one-on-one.mixin";
import PermissionsMixin from "./mixins/permissions.mixin";

/**
 * Approval Actions and 'Delete/Cancel' Buttons used by a `Schedule Admin` to manage
 * a `OneOnOne` appointment request
 */
export default {
  name: "ApproveOneOnOneControls",

  mixins: [PermissionsMixin, OneOnOneMixin],

  props: {
    cancelingRequest: Boolean,
    deletingRequest: Boolean,
    decliningRequest: Boolean,
    disableConfirm: Boolean,
    oneOnOne: Object
  },

  computed: {
    actions() {
      const { disableConfirm, isPending, needsConfirmation } = this;

      return [
        // Delete
        {
          text: "Delete",
          icon: "fas fa-times-circle",
          disabled: isPending,
          class: {
            "col-33": isPending,
            "outline error--text": isPending,
            error: !isPending,
            wide: this.isCanceled || this.isDeclined
          },
          click: () => this.$emit("delete-request"),
          condition: !needsConfirmation && (this.isCanceled || this.isDeclined)
        },

        // "Decline Request"
        {
          text: "Decline",
          icon: "fas fa-comment-slash",
          class: {
            "col-50 error--text": isPending,
            decline: isPending,
            outline: !isPending
          },
          click: () => this.$emit("decline-request"),
          condition: isPending && !needsConfirmation
        },

        // "Cancel" Action
        {
          text: "Nope (Back)",
          icon: `fas fa-arrow-left`,
          disabled: !needsConfirmation,
          class: {
            "col-33": !needsConfirmation,
            "col-50 error--text": isPending || needsConfirmation,
            decline: isPending,
            outline: !isPending
          },
          click: () => this.$emit("decline-action"),
          condition: needsConfirmation
        },

        // "Cancel Request" Action
        {
          text: "Cancel Request",
          icon: `fas fa-times-circle`,
          class: {
            "error--text outline": true,
            "col-33": isPending,
            wide: this.isActiveRequest
          },
          click: () => this.$emit("cancel-request"),
          condition: this.isActiveRequest && !needsConfirmation
        },

        // "Approve Request" or "Confirm" Action
        {
          text: needsConfirmation ? "Confirm" : "Approve",
          icon: "fas fa-check",
          disabled: !needsConfirmation && (disableConfirm || !isPending),
          class: {
            grow: true,
            success: isPending && !needsConfirmation,
            outline: needsConfirmation || !isPending,
            "success--text": needsConfirmation
          },
          click: () => this.$emit("confirm-action"),
          condition: isPending || needsConfirmation
        }
      ].filter(({ condition }) => condition);
    },

    isActiveRequest() {
      return this.isApproved || this.isConfirmed;
    },

    needsConfirmation() {
      return (
        this.deletingRequest || this.decliningRequest || this.cancelingRequest
      );
    }
  }
};
</script>

<style lang="scss" scoped src="./ApproveOneOnOne.Form.scss"></style>
