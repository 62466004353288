import apiConfig from "@network";
import { sortBy } from "lodash";
import { dateTimeForServer } from "../helpers/date-time.helpers";
import {
  categoryDescriptionFor,
  oneOnOneStatuses
} from "../helpers/one-on-one.helpers";
import OneOnOneEvent, { OneOnOneJson } from "./oneOnOne.model";
import { getUserById } from "./user";
import { scheduleMeeting } from "./zoom";

export async function createOrUpdateOneOnOne(form, src = {}) {
  const { scheduledDate, backupDate1, backupDate2, scheduledTimeZone } = form;
  const model = OneOnOneJson(form);
  model.scheduledDate = dateTimeForServer(scheduledDate, scheduledTimeZone);

  // Add backup dates if present
  [backupDate1, backupDate2].forEach(date => {
    if (date && !date.invalid) {
      model[date] = dateTimeForServer(date, scheduledTimeZone);
    }
  });

  return src.id ? updateOneOnOne(model) : scheduleOneOnOne(model);
}

export async function confirmOneOnOne(form) {
  // schedule zoom
  const zoomLinks = await scheduleMeeting({
    name: form.name,
    scheduledDate: form.scheduledDate,
    description: categoryDescriptionFor(form),
    scheduledTimeZone: form.scheduledTimeZone,
    meetingLength: 60,
    zoomUserId: form.zoomUserId
  });
  // update 1:1 [ approvalStatus = "Confirmed" ]
  const approvalStatus = oneOnOneStatuses.CONFIRMED;
  const params = OneOnOneJson({ ...form, ...zoomLinks, approvalStatus });

  return await updateOneOnOne(params);
}

/**
 *
 * @param {object} params Request params
 * @param {number} params.id Instructor or Teacher Id
 * @param {string} params.scheduledDate requested date for 1:1
 */
export async function checkTeacherAvailability(params) {
  const { message } = await apiConfig.oneOnOne.checkTeacherAvailability(params);
  return message === "No conflicts" ? message : Promise.reject({ message });
}

export async function getOneOnOneById(eventId) {
  const { data } = await apiConfig.oneOnOne.getOneOnOneById({ eventId });
  return OneOnOneEvent(data);
}

async function scheduleOneOnOne(form) {
  const data = await apiConfig.oneOnOne.scheduleOneOnOne(form);
  return data.message ? Promise.reject(data) : OneOnOneEvent(data);
}

async function updateOneOnOne(form) {
  const params = OneOnOneJson(form);
  params.eventId = form.id;
  // Additional stuff
  const { data, message } = await apiConfig.oneOnOne.updateOneOnOne(params);
  return Array.isArray(data) && data[0] === 1
    ? getOneOnOneById(form.id)
    : Promise.reject(message);
}

/**
 * List `One-on-One` appointments filtered by optional params
 * @param {object} opts Request params
 * @param {object} opts.assignedTeacher Id of requested `Teacher` for Scheduling
 * @param {object} opts.requestedBy Id of requesting `User`
 */
export async function listOneOnOnes(opts = {}) {
  // Fetch list, teachers, and requesters
  const { data } = await apiConfig.oneOnOne.listOneOnOne(opts);
  return fetchAndAttachUsers(data);
}

async function fetchAndAttachUsers(data) {
  const ids = new Set();
  const userRequests = [];
  const teacherKeys = ["assignedTeacher", "requestedBy"];

  data.forEach(event => {
    teacherKeys.forEach(teacherKey => {
      if (ids.has(event[teacherKey])) return;
      ids.add(event[teacherKey]);
      userRequests.push(getUserById(event[teacherKey]));
    });
  }, ids);

  const users = await Promise.all(userRequests);
  const user = userId => users.find(({ id }) => id === userId);

  // Return one-on-ones
  return sortBy(
    data.map(e => ({
      ...OneOnOneEvent(e),
      assignedTeacher: user(e.assignedTeacher),
      requestedBy: user(e.requestedBy)
    })),
    ["scheduledDate"]
  ).reverse();
}

export async function deleteOneOnOne(eventId) {
  const data = await apiConfig.oneOnOne.deleteOneOnOne({ eventId });
  return data;
}
