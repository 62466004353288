import {
  utcFromISO,
  currentTimeUTC,
  assertNow
} from "../helpers/date-time.helpers";
import {
  oneOnOneStatuses,
  statusDescription,
  statusIcon,
  statusIconColor,
  statusText
} from "../helpers/one-on-one.helpers";
import { findTZByText } from "./zoom";

export default function OneOnOneEvent(data = {}) {
  const json = OneOnOneJson(data);
  const oneOnOne = {
    ...json,

    get backupDateTime1() {
      return oneOnOne.backupDate1
        ? oneOnOne.backupDateTime1UTC.setZone(oneOnOne.scheduledTimeZone)
        : null;
    },

    get backupDateTime1UTC() {
      return utcFromISO(oneOnOne.backupDate1);
    },

    get backupDateTime2() {
      return oneOnOne.backupDate2
        ? oneOnOne.backupDateTime2UTC.setZone(oneOnOne.scheduledTimeZone)
        : null;
    },

    get backupDateTime2UTC() {
      return utcFromISO(oneOnOne.backupDate2);
    },

    get approvalExpiresDateTime() {
      const date = oneOnOne.approvalExpires;
      return date ? utcFromISO(date) : null;
    },

    get approvalIsExpired() {
      const expires = oneOnOne.approvalExpiresDateTime;
      return oneOnOne.approvalStatus === oneOnOneStatuses.APPROVED && expires
        ? expires <= currentTimeUTC()
        : false;
    },

    get beginsToRelative() {
      return this.scheduledDateTimeUTC.toRelative();
    },

    get canStart() {
      const start = this.scheduledDateTimeUTC;
      const isToday = assertNow().isSame(start, start.zoneName);
      const nowMS = currentTimeUTC().toMillis();
      const sameOrAfterStart = nowMS >= start.toMillis();
      const beforeEnd = nowMS < start.plus({ minutes: 30 });

      return isToday && sameOrAfterStart && beforeEnd;
    },

    get hasEnded() {
      const start = this.scheduledDateTimeUTC;
      const nowMS = currentTimeUTC().toMillis();
      return nowMS > start.plus({ minutes: 45 });
    },

    get isApproved() {
      return json.approvalStatus === oneOnOneStatuses.APPROVED;
    },

    get isCanceled() {
      return json.approvalStatus === oneOnOneStatuses.CANCELED;
    },

    get isComplete() {
      return json.approvalStatus === oneOnOneStatuses.COMPLETE;
    },

    get isConfirmed() {
      return json.approvalStatus === oneOnOneStatuses.CONFIRMED;
    },

    get isDeclined() {
      return json.approvalStatus === oneOnOneStatuses.DECLINED;
    },

    get isPending() {
      return json.approvalStatus === oneOnOneStatuses.PENDING;
    },

    get isToday() {
      const start = this.scheduledDateTimeUTC;
      return assertNow().isSame(start, start.zoneName);
    },

    get isUpcoming() {
      const start = this.scheduledDateTimeUTC;
      return assertNow().isBefore(start, start.zoneName);
    },

    get scheduledDateTime() {
      return oneOnOne.scheduledDateTimeUTC.setZone(data.scheduledTimeZone);
    },

    get scheduledDateTimeUTC() {
      return utcFromISO(data.scheduledDate);
    },

    get scheduledTZAbbr() {
      const zoneByText = findTZByText(oneOnOne.scheduledTimeZone);
      return zoneByText && zoneByText.abbr;
    },

    get statusColor() {
      return statusIconColor(
        oneOnOne.approvalIsExpired
          ? oneOnOneStatuses.CANCELED
          : oneOnOne.approvalStatus
      );
    },

    get statusDescription() {
      return oneOnOne.approvalIsExpired
        ? "Your confirmation window has expired: please submit a new one-on-one request"
        : statusDescription(oneOnOne.approvalStatus);
    },

    get statusIcon() {
      return oneOnOne.approvalIsExpired
        ? "fas fa-calendar-times error-light--text"
        : statusIcon(oneOnOne.approvalStatus);
    },

    get statusIconColor() {
      return oneOnOne.approvalIsExpired
        ? statusIconColor(oneOnOneStatuses.CANCELED)
        : statusIconColor(oneOnOne.approvalStatus);
    },

    get statusText() {
      return oneOnOne.approvalIsExpired
        ? "Request Approval Expired"
        : statusText(oneOnOne.approvalStatus);
    }
  };

  return oneOnOne;
}

export function OneOnOneJson(data) {
  const approvalStatus = data.approvalStatus || oneOnOneStatuses.PENDING;

  const json = {
    name: data.name || null,
    approved: Boolean(data.zoomMeetingId),
    approvalStatus,
    approvalExpires: data.approvalExpires || null,
    requiresDonation: data.requiresDonation || false,
    requestedBy: data.requestedBy || null,
    scheduledDate: data.scheduledDate || null,
    backupDate1: data.backupDate1 || null,
    backupDate2: data.backupDate2 || null,
    scheduledTimeZone: data.scheduledTimeZone || null,
    zoomLink: data.zoomLink || null,
    zoomMeetingId: data.zoomMeetingId || null,
    zoomStartLink: data.zoomStartLink || null,
    createdAt: data.createdAt || null
  };

  // Data id
  if (data.id) json.id = data.id;

  // Attach user ids if user data exists
  ["assignedTeacher", "requestedBy"].forEach(key => {
    const teacher = data[key] || {};
    const dataType = typeof teacher;

    if (teacher.id) json[key] = teacher.id;
    else if (dataType === "number") json[key] = data[key];
  });

  return json;
}

export function cloneOneOnOneEvent(eventData) {
  const copy = OneOnOneEvent(eventData);
  copy.assignedTeacher = eventData.assignedTeacher;
  copy.requestedBy = eventData.requestedBy;
  return copy;
}
