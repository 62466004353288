<template>
  <label for="teacher" class="mvp-instructor-select">
    <span :class="{ [requiredField('teacher')]: required, h6: !required }">
      <i class="fas fa-chalkboard-teacher accent--text" v-if="showIcon" />
      {{ labelText }}
    </span>

    <select
      name="teacher"
      @change="onTeacherSelect($event.target.value)"
      v-model="form.teacher"
    >
      <option :value="null">Select {{ type }}:</option>
      <option v-if="assigneeNotInList" :value="form.teacher">
        ( -- Assignee not in list -- )
      </option>
      <optgroup :label="instructorType">
        <option
          v-for="teacher in teachers"
          :key="teacher.id"
          :value="teacher.id"
        >
          {{ teacher.fullName }}
        </option>
      </optgroup>
    </select>
  </label>
</template>

<script>
import FormsMixin from "./mixins/forms.mixin";
import { getPractitioners, getTeachers } from "../models/user";

const INSTRUCTOR_TYPES = new Set(["Teachers", "Practitioners"]);

export default {
  name: "MvpInstructorSelect",

  mixins: [FormsMixin],

  props: {
    instructorType: {
      type: String,
      required: true,
      validate: INSTRUCTOR_TYPES.has
    },

    labelText: {
      type: String,
      default: "Requested Practitioner"
    },

    required: Boolean,

    showIcon: {
      type: Boolean,
      default: true
    },

    value: [Number, Object]
  },

  data: () => ({ teachers: [] }),

  computed: {
    assigneeNotInList() {
      const teacherIds = this.teachers.map(({ id }) => id);
      const selected = this.form.teacher;
      return Boolean(selected) && !new Set(teacherIds).has(selected);
    },

    requiredFields() {
      return ["teacher"];
    },

    type() {
      const typeLength = this.instructorType.length - 1;
      return this.instructorType.substr(0, typeLength);
    }
  },

  watch: {
    value: function _onTeacherChange(newVal) {
      this.form = { teacher: null };
      if (newVal) this.hydrateTeacher(newVal);
    }
  },

  async mounted() {
    const requestType = {
      Teachers: getTeachers,
      Practitioners: getPractitioners
    };

    this.teachers = await requestType[this.instructorType]();
    if (this.value) this.hydrateTeacher(this.value);
  },

  methods: {
    hydrateTeacher(teacher) {
      switch (typeof teacher) {
        case "object":
          return !teacher.id
            ? null
            : this.appendFormData({ teacher: teacher.id });

        case "number":
          return this.appendFormData({ teacher });

        default:
          return null;
      }
    },

    onTeacherSelect(teacherId) {
      const data = this.teachers.find(({ id }) => id === Number(teacherId));
      this.appendFormData({ data });
      this.emitFormData();
    }
  }
};
</script>

<style lang="scss">
.mvp-instructor-select {
  > * {
    width: $parent;
  }
}
</style>
