<template>
  <section id="approve-one-on-one">
    <loader v-if="loading" :loadingMessage="loadingMessage" />

    <!-- All requests (Schedule Admin) -->
    <list-one-on-one-list
      show-empty-message
      :requests="requests"
      :show-legend="isScheduleAdminUser"
      v-if="isScheduleAdminUser"
      list-title="Pending Requests"
      list-empty-message="No users have submitted any requests: please check again later!"
      @select-item="select($event.selected, $event.index)"
    />

    <!-- Modal for selected 1-on-1 -->
    <modal-component
      :title="(selected || {}).name"
      :visible="Boolean(selected)"
      @close="select(null)"
    >
      <hr class="divider divider--tiny" />

      <approve-one-on-one-form
        v-if="!loading && selected"
        :formData="selected"
        :swapped-teacher="form.updateInstructor"
        :swapped-schedule="form.updateSchedule"
        @approve="approveRequest"
        @change="appendFormData"
        @decline="declineRequest"
        @cancel-request="cancelRequest"
        @delete-request="deleteRequest"
        @swap-schedule="swapSchedule"
        @swap-teacher="swapTeacher"
      />
    </modal-component>
  </section>
</template>

<script>
import { dateTimeFromISO } from "../helpers/date-time.helpers";
import { oneOnOneStatuses } from "../helpers/one-on-one.helpers";
import {
  createOrUpdateOneOnOne,
  deleteOneOnOne,
  listOneOnOnes
} from "../models/oneOnOne";
import { cloneOneOnOneEvent } from "../models/oneOnOne.model";
import ApproveOneOnOneForm from "./ApproveOneOnOne.Form.vue";
import ListOneOnOneList from "./ListOneOnOne.List.vue";
import Loader from "./Loader.vue";
import DateTimeMixin from "./mixins/date-time.mixin";
import FormsMixin from "./mixins/forms.mixin";
import PermissionsMixin from "./mixins/permissions.mixin";
import ModalComponent from "./ModalComponent.vue";

export default {
  name: "ApproveOneOnOne",

  mixins: [DateTimeMixin, PermissionsMixin, FormsMixin],

  components: {
    ApproveOneOnOneForm,
    ListOneOnOneList,
    ModalComponent,
    Loader
  },

  data: () => ({
    form: {
      requiresDonation: false,
      sIndex: -1,
      updateSchedule: false,
      updateInstructor: false
    },
    requests: [],
    requiredFields: ["requiresDonation"],
    selected: null
  }),

  async mounted() {
    if (!this.activeUser || !this.isScheduleAdminUser) {
      return this.$router.push({ name: "OneOnOne" });
    }

    this.requests = await listOneOnOnes();
  },

  methods: {
    async approveRequest() {
      const { form, selected: params } = this;
      const src = this.requests[form.sIndex];
      params.approvalStatus = oneOnOneStatuses.APPROVED;
      params.requiresDonation = form.requiresDonation;
      params.approvalExpires = dateTimeFromISO(new Date().toISOString())
        .plus({ days: 2 })
        .set({ second: 0, millisecond: 0 })
        .toUTC();

      if (form.updateSchedule) {
        params.backupDate1 = null;
        params.backupDate2 = null;
      }

      return await this.updateRequest(params, src, "Approving request...");
    },

    async cancelRequest() {
      const { form, selected } = this;
      const req = this.requests[form.sIndex];
      selected.approvalStatus = oneOnOneStatuses.CANCELED;
      return await this.updateRequest(selected, req, "Canceling request ...");
    },

    async declineRequest() {
      const { form, selected } = this;
      const req = this.requests[form.sIndex];
      selected.approvalStatus = oneOnOneStatuses.DECLINED;
      return await this.updateRequest(selected, req, "Declining request ...");
    },

    async deleteRequest() {
      this.startLoading(`Removing request ...`);
      try {
        await deleteOneOnOne(this.selected.id);
        return await this.updateList();
      } catch (error) {
        this.onViewError(error.message || error);
      }
    },

    async updateRequest(params, source, msg) {
      this.startLoading(msg);

      try {
        await createOrUpdateOneOnOne(params, source);
        return await this.updateList();
      } catch (error) {
        this.onViewError(error.message || error);
      }
    },

    select(selected, sIndex = -1) {
      // select (or deselect) a 1:1 for review
      this.selected = selected ? { ...selected } : null;
      // reset tracked properties
      const { requiresDonation } = selected || { requiresDonation: false };
      this.appendAndEmit({
        requiresDonation,
        sIndex,
        updateInstructor: false,
        updateSchedule: false
      });
    },

    swapTeacher(assignedTeacher) {
      // get ref to original selected event list item
      const source = this.requests[this.form.sIndex];
      // check "update on save" boolean if original instructor has changed
      const updateInstructor = source.assignedTeacher.id !== assignedTeacher.id;
      // swap 'assignedTeacher' prop with incoming teacher and update UI
      this.selected = cloneOneOnOneEvent({ ...this.selected, assignedTeacher });
      this.appendFormData({ updateInstructor });
    },

    swapSchedule(altDate) {
      if (this.selected) {
        // get ref to original selected event list item
        const src = this.requests[this.form.sIndex];
        // swap 'scheduledDate' prop with incoming date and update UI
        const scheduledDate = altDate || src.scheduledDate;
        this.selected = cloneOneOnOneEvent({ ...this.selected, scheduledDate });
        // change date on save if original schedule has changed
        this.appendFormData({ updateSchedule: altDate !== null });
      }
    },

    async updateList() {
      this.select(null);
      this.startLoading("Refreshing list ...");
      this.requests = await listOneOnOnes();
      this.stopLoading();
    }
  }
};
</script>
